<template>
  <div class="coupon-cont">
    <cube-tab-bar v-model="selectedLabel" show-slider class="taps">
      <cube-tab v-for="(item) in tabs" :label="item.label" :key="item.label">
      </cube-tab>
    </cube-tab-bar>
    <cube-tab-panels v-model="selectedLabel">
      <cube-tab-panel  label="待使用">
        <div class="list">
          <div class="box shadow" v-for="(item, index) in data.list1" :key="index">
            <div class="cont">
              <div class="left">
                <span>{{item.num}}</span>
                元
              </div>
              <div class="line"></div>
              <div class="right">
                <div class="name">{{item.type | typeToStr('name')}}</div>
                <p>{{item.type | typeToStr('type')}}</p>
                <p>有效期至：{{item.end | dayF}}</p>
              </div>
              <div class="btns">
                <cube-button v-if="item.type ==1" class="btn" @click="goPath('/coupon/qr/' + item.id +'?order=' + id)">立即使用</cube-button>
              </div>
            </div>
            <div class="title">
              {{item.type | typeToStr('describe')}}
            </div>
          </div>
        </div>
      </cube-tab-panel>
      <cube-tab-panel  label="已使用">
        <div class="list">
          <div class="box shadow" v-for="(item, index) in data.list2" :key="index">
            <div class="cont">
              <div class="left">
                <span>{{item.num}}</span>
                元
              </div>
              <div class="line"></div>
              <div class="right">
                <div class="name">{{item.type | typeToStr('name')}}</div>
                <p>{{item.type | typeToStr('type')}}</p>
                <p>有效期至：{{item.end | dayF}}</p>
              </div>
              <div class="btns">
                <img src="@/assets/img/y1.png" alt="">
              </div>
            </div>
            <div class="title">
              {{item.type | typeToStr('describe')}}
            </div>
          </div>
        </div>
      </cube-tab-panel>
      <cube-tab-panel label="已失效">
        <div class="list">
          <div class="box shadow disabled" v-for="(item, index) in data.list3" :key="index">
            <div class="cont">
              <div class="left">
                <span>{{item.num}}</span>
                元
              </div>
              <div class="line"></div>
              <div class="right">
                <div class="name">{{item.type | typeToStr('name')}}</div>
                <p>{{item.type | typeToStr('type')}}</p>
                <p>有效期至：{{item.end | dayF}}</p>
              </div>
              <div class="btns">
                <img src="@/assets/img/y2.png" alt="">
              </div>
            </div>
            <div class="title">
              {{item.type | typeToStr('describe')}}
            </div>
          </div>
        </div>
      </cube-tab-panel>
    </cube-tab-panels>
  </div>
</template>

<script>
export default {
  name: 'coupon',
  data () {
    return {
      id: this.$route.params.id,
      data: {
        list1: [],
        list2: [],
        list3: [],
      },
      selectedLabel: '待使用',
      tabs: [{
        label: '待使用',
      }, {
        label: '已使用',
      }, {
        label: '已失效',
      }]
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    /**
     * 获取数据
     */
    getData () {
      const vm = this;
      if(! this.id){
        this.$createToast({
          txt: '无保单信息',
          type: 'error'
        }).show()
        return
      }
      let postData = {
        state: '',
        order: this.id,
      }
      vm.$store.commit('updateLoader', true);
      this.$http
        .post("/ticket/ticketList", postData)
        .then(function(response) {
          vm.$store.commit('updateLoader', false);
          if (response.data.header.code == "A1000") {
            if(response.data.body.list && response.data.body.list.length > 0){
              vm.formatData(response.data.body.list)
            }else{
              vm.$createToast({
                txt: '未查询到待使用的优惠券信息',
                type: 'warn'
              }).show()
            }
          } else {
            vm.$createToast({
              txt: response.data.header.msg,
              type: 'warn'
            }).show()
          }
        })
        .catch(function() {
          vm.$store.commit('updateLoader', false);
          vm.$createToast({
            txt: '网络错误',
            type: 'error'
          }).show()
        });
    },
    /**
     * 数据处理
     */
    formatData (list) {
      list.forEach(e => {
        if(e.state == 1){
          this.data.list1.push(e)
        }else if(e.state == 2 || e.state == 4){
          this.data.list2.push(e)
        }else if(e.state == 3){
          this.data.list3.push(e)
        }
      })
    },
    /**
     * 跳转页面
     */
    goPath (path) {
      this.$router.push(path)
    },
  },
  filters: {
    dayF (str) {
      if(str){
        return str.substring(0, 10)
      }else{
        return ''
      }
    },
    typeToStr (type, s) {
      if(type == 1){
        let obj = {
          name: '门店购机优惠券',
          type: '购机立减',
          describe: '门店销售专用，与网上购机优惠卷二选一使用',
        }
        return obj[s]
      }else if(type ==2){
        let obj = {
          name: '网上购机优惠券',
          type: '购机立减',
          describe: '网上购机专用，与门店购机优惠券二选一使用',
        }
        return obj[s]
      }else if(type ==3){
        let obj = {
          name: '回收代金券',
          type: '换新抵扣',
          describe: '回收置换新机时抵扣，与换机补贴券同时使用',
        }
        return obj[s]
      }else if(type ==4){
        let obj = {
          name: '换机补贴券',
          type: '换新抵扣',
          describe: '回收置换新机时抵扣，与回收代金券同时使用',
        }
        return obj[s]
      }else{
        return ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.taps{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background: #fff;
  z-index: 90;
}

.box{
  margin: 0 30px 30px 30px;
  padding: 24px 30px 0px 30px;
  border-radius: 14px;
  &.disabled{
    *{
      color: #CCCCCC;
    }
    .name{
      color: #CCCCCC !important;
    }
    .cont .left span{
      color: #ccc;
    }
  }
  .cont{
    display: flex;
    .left{
      padding: 33px 0;
      width: 180px;
      span{
        font-size: 70px;
        color: #EE7B86;
        letter-spacing: 0;
        line-height: 50px;
      }
      font-size: 26px;
      color: #888888;
    }
    .line{
      border-left: 1px dashed #208170;
    }
    .right{
      flex: 10;
      margin-left: 30px;
      padding-top: 16px;
      .name{
        font-size: 28px;
        color: #333333;
        line-height: 28px;
      }
      p{
        font-size: 24px;
        color: #999999;
        line-height: 24px;
        margin-top: 10px;
      }
    }
    .btns{
      padding-top: 40px;
      .btn{
        background: #208170;
        border-radius: 24.5px;
        border-radius: 24.5px;
        padding: 0;
        height: 48px;
        width: 148px;
        font-size: 24px;
        line-height: 48px;
      }
      img{
        display: block;
        width: 96px;
        margin-top: -20px;
      }
    }
  }
  .title{
    font-size: 24px;
    color: #999999;
    line-height: 24px;
    padding: 20px 0;
    border-top: 1px solid #DEDEDE;
    margin-top: 23px;
  }
}
.list{
  padding-top: 126px;
}
</style>